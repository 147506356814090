<template>
	<div>
		<el-date-picker
				v-model="fileYear"
				clearable
				value-format="yyyy"
				type="year"
				@change="changeYear()"
				editable
				placeholder="选择年份，默认当前赛季"
				:default-value="this.currentDate"
			>
		</el-date-picker>
		<h2>{{this.currentYear}}年已上传的{{ this.filetype }}</h2>
		<el-table :data="tableData" style="width: 100%">
			<el-table-column prop="time" label="上传日期" width="280" />
			<el-table-column label="文件名（点击文件名可以预览pdf）">
				<template slot-scope="scope">
					<el-link :href="scope.row.viewpath" target="_blank">
						{{scope.row.name}}
					</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="文件ID" width="80"/>
			<el-table-column prop="username" label="上传者" width="150"/>
			<el-table-column fixed="right" label="操作" width="100">
				<template slot-scope="scope">
					<el-link :href="'/api/file/download?id=' + String(scope.row.id)">
						<i class="el-icon-download" />下载
					</el-link>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<style scoped>
</style>

<script>
import axios from "axios";
export default {
	data() {
		return {
			uploadUrl: "", // there'll be errors in console without this param
			fileList: [],
			headers: {},
			userid: "",
			isUploadNeeded: false,
			tableData: [{}],
			fileYear: null,
			currentYear: 0,
			currentDate: null,
		};
	},
	props: {
		filetype: String,
	},
	beforeMount() {
		// console.log("filetype=");
		// console.log(this.filetype);
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/hello-user",
				method: "GET",
			}).then((res) => {
				this.userid = res.data.userid;
				// console.log("userid is" + this.userid);
			});
			axios({
				url: "/api/game/getyear",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.currentYear = res.data.year;
					this.currentDate = new Date().setFullYear(res.data.year);
				})
				.catch((err) => {
					this.messageBox(
						"获取当前赛季失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get current year Failed");
					console.log(err.response);
				});
			axios({
				url: "/api/file/get?type=" + this.filetype,
				method: "GET",
			}).then((res) => {
				// console.log(res);
				this.tableData = res.data.data;
				for (let item of this.tableData) {
					item.time = item.time.split(".")[0]+' UTC+8';
				}
			});
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		changeYear() {
			axios({
				url: "/api/file/get?type=" + this.filetype + "&year=" + String(this.fileYear),
				method: "GET",
			}).then((res) => {
				// console.log(res);
				this.tableData = res.data.data;
				for (let item of this.tableData) {
					item.time = item.time.split(".")[0]+' UTC+8';
				}
			});
			this.currentYear = Number(this.fileYear);
		},
		submitUpload() {
			console.log("CLicked!");
			this.$refs.upload.submit();
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		downloader(data) {
			// 文件导出
			if (!data) {
				return;
			}
			let url = window.URL.createObjectURL(new Blob([data]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;
			link.setAttribute("download", "");

			document.body.appendChild(link);
			link.click();
		},
		convertRes2Blob(response) {
			// 提取文件名
			let fileName = "123";
			// String(response.headers["content-disposition"]).match(
			// 	/filename=(.*)/
			// )[1];
			// 将二进制流转为blob
			let blob = new Blob([response.data], {
				type: "application/octet-stream,charset=UTF-8",
			});
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
				window.navigator.msSaveBlob(blob, decodeURI(fileName));
			} else {
				// 创建新的URL并指向File对象或者Blob对象的地址
				let blobURL = window.URL.createObjectURL(blob);
				// 创建a标签，用于跳转至下载链接
				let tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", decodeURI(fileName));
				// 兼容：某些浏览器不支持HTML5的download属性
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}
				// 挂载a标签
				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				// 释放blob URL地址
				window.URL.revokeObjectURL(blobURL);
			}
		},
		uploadSectionFile(fileObj) {
			let dataObj = new FormData();
			dataObj.append("file", fileObj.file);
			dataObj.append("user", String(this.userid));
			dataObj.append("type", this.filetype);

			// Params here need to be customised
			console.log(fileObj.file);
			if (localStorage.getItem("token")) {
				axios.defaults.headers.common["Authorization"] = localStorage.getItem(
					"token"
				);
			}
			axios({
				method: "POST",
				url: "/api/file/upload",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				data: dataObj,
			})
				.then((res) => {
					console.log("successfully Uploaded");
					console.log(res);
					this.messageBox(
						"上传成功，请等待审核 文件id为" + res.data.id,
						"success"
					);
				})
				.catch((err) => {
					console.log(err.response);
					this.messageBox(
						"上传失败，"+ err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error");
				});
		},
	},
};
</script>