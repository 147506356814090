<template>
	<reviewer :filetype="this.filetype"/>
</template>

<script>
import Reviewer from '../Reviewer.vue';
export default {
	data(){
		return {
			filetype:"赛事章程"
		}
	},
	components: { Reviewer },
}
</script>